.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; // Full height of the viewport

  .login-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border-radius: 8px;
    background-color: #2b2b2b; // Dark background
    border: 2px solid #3d3d3d;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.5);
  }
}
