// storyline.scss
body {
  background-color: var(--color-white);
}

.story-page {
  h2[contenteditable='true']:focus,
  p[contenteditable='true']:focus {
    outline: none;
    border: 1px solid var(--color-accent);
    border-radius: 4px;
    padding: 2px;
  }

  h4 {
    color: var(--color-cursor) !important;
  }

  .story-page-container {
    position: relative;
    color: var(--color-dark);
    padding: 0 30px 30px;
  }

  .story-blocks-wrapper {
    border: 1px solid var(--color-sidebar);
    border-radius: 8px;
    padding: 20px;
    display: flex;
    position: relative;
    overflow-x: scroll;

    .first-block {
      .story-block.has-video {
        border: 4px solid var(--color-accent);
      }
    }

    .storyblock-wrapper {
      position: relative;

      .storyblock-connector {
        background: var(--color-accent);
        position: absolute;
        left: 100%;
        top: 50%;
        height: 4px;
        overflow: hidden;
        width: 62px;
        z-index: 10;
      }
    }
  }

  .story-column {
    display: inline-block;
    vertical-align: top;
    position: relative;

    &:last-child {
      margin-right: 0;
    }

    .column-title {
      margin-bottom: 10px;

      input {
        width: 100%;
        padding: 8px;
        font-size: 16px;
        border: 1px solid var(--color-tertiary);
        border-radius: 4px;
        background-color: var(--color-white);
        color: var(--color-cursor);
        outline: none;
        text-align: center;

        &::placeholder {
          color: var(--color-placeholder);
        }

        &:focus {
          border-color: var(--color-accent);
        }
      }
    }
  }

  .column-separator {
    position: relative;
    width: 1px;
    background-color: var(--color-tertiary);
    margin: 0 30px;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: var(--color-white);
    position: relative;
    padding-left: 20px;
    border-bottom: 1px solid var(--color-secondary);
    margin-bottom: 10px;

    .header-left,
    .header-right {
      display: flex;
      align-items: center;
      color: rgba(0, 0, 0, 0.5);
    }

    .header-center {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .logo-image {
      height: 40px;
      width: auto;
      opacity: 0.55;
    }

    .action-button {
      border-top: 0;
      border-bottom: 0;
      border-left: 1px solid var(--color-secondary);
      border-right: 1px solid var(--color-secondary);
      cursor: pointer;
      transition: background-color 0.3s;
      font-size: 14px;
      font-weight: bold;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      height: 58.5px; /* Add this line to set a fixed height */
      box-sizing: border-box;

      h2 {
        display: flex;
        align-items: center;
        gap: 5px;

        .material-symbols-outlined {
          font-size: 24px;
          position: relative;
          top: 0px !important;
          padding: 0 3px;
        }
      }

      select {
        margin-left: 10px;
        padding: 5px;
        border: none;
        background-color: var(--color-primary);
        color: rgba(0, 0, 0, 0.5);
        font-size: 14px;
        font-weight: bold;
        outline: none;
        cursor: pointer;
      }

      button {
        background-color: transparent;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        padding: 0;
        margin: 0;

        &:focus {
          outline: none;
        }
      }
    }

    .logo-and-user-info {
      display: flex;
      align-items: center;

      .logo-box {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        font-size: 14px;
        font-weight: bold;
        color: white;
      }

      .user-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        align-items: flex-start;

        .user-name,
        .membership-status {
          font-weight: bold;
        }

        .user-name {
          font-size: 14px;
        }

        .membership-status {
          font-size: 10px;
        }
      }
    }

    .version-container {
      display: flex;
      align-items: center;
      gap: 10px;

      label {
        font-size: 14px;
        font-weight: bold;
      }

      select {
        padding: 8px;
        border: 1px solid var(--color-secondary);
        border-radius: 4px;
        font-size: 14px;
        outline: none;
        transition: border-color 0.3s ease;
        background-color: var(--color-white);
        color: var(--color-dark);

        &:focus {
          border-color: var(--color-accent);
        }
      }
    }

    .share-container {
      position: relative;
    }

    .share-dropdown {
      position: absolute;
      top: calc(100% + 10px);
      right: 0;
      background-color: var(--color-primary);
      border: 1px solid var(--color-secondary);
      border-radius: 4px;
      padding: 10px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      z-index: 10;
      min-width: 200px;
      opacity: 1; /* Add this line */
      visibility: visible;

      h3 {
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 10px;
      }

      .collaborator-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        opacity: 1; /* Add this line */
        transition: opacity 0.3s ease; /* Add this line */

        span {
          font-size: 14px;
        }

        select {
          padding: 4px;
          border: 1px solid var(--color-secondary);
          border-radius: 4px;
          font-size: 12px;
          outline: none;
          transition: border-color 0.3s ease;
          // background-color: var(--color-primary);
          color: var(--color-dark);

          &:focus {
            border-color: var(--color-accent);
          }
        }
      }

      .add-collaborator-button {
        margin-top: 10px;
        padding: 8px 16px;
        border: none;
        border-radius: 4px;
        background-color: var(--color-accent);
        color: var(--color-primary);
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: var(--color-accent-hover);
        }
      }
    }
  }

  .upload-instructions-container {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 15px;

    .upload-heading-row {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }

    .content-row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
    }

    .upload-heading {
      font-size: 24px;
      font-weight: bold;
      color: var(--color-dark);
      text-align: left;
    }

    .version-preview-container {
      display: flex;
      align-items: center;
    }

    p {
      color: var(--color-cursor);
      margin-right: auto;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .modal-content {
    position: relative;
    max-width: 80%;
    max-height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    outline: none;
  }

  .modal-video {
    width: 100%;
    height: auto;
    max-width: 1200px;
    max-height: 80vh;
    object-fit: contain;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
  }

  .escape-preview-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--color-primary);
    color: var(--color-cursor);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: var(--color-accent);
      color: var(--color-primary);
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(var(--color-accent), 0.3);
    }
  }

  .preview-button,
  .project-chat-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: var(--color-accent);
    color: var(--color-primary);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: var(--color-accent-hover);
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(var(--color-accent-rgb), 0.3);
    }
  }

  .save-project-button {
    border-left: 1px solid var(--color-secondary);
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    height: 58.5px;
    box-sizing: border-box;

    h2 {
      display: flex;
      align-items: center;
      gap: 5px;
      color: rgba(0, 0, 0, 0.5);

      .material-symbols-outlined {
        font-size: 24px;
        position: relative;
        top: 0px !important;
        padding: 0 3px;
      }
    }
  }

  .save-project-modal {
    background-color: var(--color-white);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;

    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    input {
      width: 100%;
      padding: 10px;
      font-size: 16px;
      border: 1px solid var(--color-secondary);
      border-radius: 4px;
      margin-bottom: 20px;
      color: var(--color-dark);

      &:focus {
        outline: none;
        border-color: var(--color-accent);
      }
    }

    .modal-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 10px;

      button {
        padding: 10px 20px;
        font-size: 16px;
        font-weight: bold;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:first-child {
          background-color: var(--color-sidebar);
          color: var(--color-dark);

          &:hover {
            background-color: var(--color-tertiary);
          }

          &:focus {
            outline: none;
          }
        }

        &:last-child {
          background-color: var(--color-accent);
          color: var(--color-white);

          &:hover {
            background-color: var(--color-accent-hover);
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .version-change-modal {
    background-color: var(--color-white);
    padding: 16px;
    border-radius: 6px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 500px;
    max-width: 90%;

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 16px;
    }

    p {
      font-size: 14px;
      margin-bottom: 16px;
      color: var(--color-dark);
    }

    .modal-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 8px;

      button {
        padding: 8px 16px;
        font-size: 14px;
        font-weight: bold;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.secondary-button {
          background-color: var(--color-white);
          color: var(--color-dark);
          border: 1px solid var(--color-sidebar);

          &:hover {
            background-color: var(--color-sidebar);
          }

          &:focus {
            outline: none;
          }
        }

        &.primary-button {
          background-color: var(--color-sidebar);
          color: var(--color-white);

          &:hover {
            background-color: var(--color-accent);
          }

          &:focus {
            outline: none;
          }
        }

        &.tertiary-button {
          background-color: var(--color-sidebar);
          color: var(--color-white);

          &:hover {
            background-color: var(--color-accent);
            color: var(--color-white);
          }

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .export-modal {
    background-color: var(--color-white);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 400px;
    max-width: 90%;

    h3 {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    .export-options {
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin-bottom: 20px;

      button {
        padding: 10px;
        font-size: 16px;
        border: 1px solid var(--color-sidebar);
        border-radius: 4px;
        background-color: var(--color-white);
        color: var(--color-dark);
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
          background-color: var(--color-accent);
          color: var(--color-white);
        }

        &:focus {
          outline: none;
        }
      }
    }

    .cancel-button {
      padding: 10px 20px;
      font-size: 16px;
      font-weight: bold;
      border: none;
      border-radius: 4px;
      background-color: var(--color-tertiary);
      color: var(--color-dark);
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: var(--color-sidebar);
      }

      &:focus {
        outline: none;
      }
    }
  }

  .add-collaborator-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  .add-collaborator-modal-content {
    background-color: var(--color-primary);
    padding: 20px;
    border-radius: 6px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 400px;
    width: 100%;

    h3 {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }

    input,
    select {
      width: 100%;
      padding: 8px;
      font-size: 16px;
      border: 1px solid var(--color-tertiary);
      border-radius: 4px;
      background-color: var(--color-primary);
      color: var(--color-cursor);
      outline: none;
      margin-bottom: 10px;

      &::placeholder {
        color: var(--color-placeholder);
      }

      &:focus {
        border-color: var(--color-accent);
      }
    }

    .modal-buttons {
      display: flex;
      justify-content: flex-end;
      margin-top: 20px;
    }

    button {
      padding: 8px 16px;
      font-size: 14px;
      font-weight: bold;
      background-color: var(--color-accent);
      color: var(--color-primary);
      border: none;
      border-radius: 6px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      margin-left: 10px;

      &:hover {
        background-color: var(--color-accent-hover);
        transform: translateY(-2px);
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      }

      &:active {
        transform: translateY(0);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px rgba(var(--color-accent-rgb), 0.3);
      }
    }
  }
}
