
//  KEEP THESE OUTSIDE OF .soundBox - also used in .musicBox
.addSoundContainer {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  background-color: transparent;
  border: 2px dashed #cccccc; /* Light grey dashed border initially */
  height: 60px;
  border-radius: 8px;
  transition: background-color 0.3s, border-color 0.3s; /* Smooth transition for background and border color change */

  &:hover {
    background-color: rgba(
        54,
        55,
        56,
        0.2
    ); /* Slight background color change on hover, adjust as needed */
    border-color: rgb(102, 69, 100); /* Deep purple border color on hover */
  }

  img {
    transition: filter 0.3s ease; /* Transition for the filter effect */
  }

  &:hover img {
    filter: invert(34%) sepia(92%) saturate(1122%) hue-rotate(282deg) brightness(90%) contrast(88%); /* Adjust filter for deep purple color */
  }
}

/* If the plus sign is still text-based, use this for hover color change */
.addSoundPlus {
  font-size: 40px; /* Adjust size as needed */
  color: #cccccc; /* Light grey color for the plus sign */
  transition: color 0.3s ease; /* Transition for color change */

  /* Hover state for text-based plus sign */
  .addSoundContainer:hover & {
    color: var(--color-accent); /* Deep purple color on hover */
  }
}

/* Styling scrollbars for Webkit browsers */
.soundContainers::-webkit-scrollbar {
  width: 8px; /* Adjust the width of the scrollbar */
}

.soundContainers::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.soundContainers::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.soundContainers::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.soundContainers {
  scrollbar-width: thin;
  scrollbar-color: #888 #555;
  border-radius: 10px;
  display: flex;
  flex-direction: column; /* Ensures sound containers are also stacked */
}

.soundHeader {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding-left: 20px;
  padding-right: 20px;
}
