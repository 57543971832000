.upload-page {
  text-align: center;

  .upload-page-container {
    position: relative;
    background-color: var(--color-primary);
    color: var(--color-dark);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .top-left-logo {
    position: fixed;
    left: 30px;
    z-index: 10;

    img {
      height: 50px; // Set the logo height to 50px
      width: auto; // Maintain aspect ratio of the logo
    }
  }

  .upload-instructions-container {
    text-align: center;
  }

  .upload-heading {
    font-size: 24px;
    font-weight: bold;
  }

  .file-dropzone {
    margin: 20px auto;
    width: 90%;
    max-width: 400px;
    height: 200px;
    border: 4px dashed var(--color-tertiary);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 20px;

    &.dragging {
      border-color: var(--color-accent);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-accent);
        opacity: 0.2; // Adjust opacity to desired level
        pointer-events: none; // Ensure the overlay doesn't interfere with drop
      }
    }
  }

  .saved-project-block {
    width: 240px;
    height: 135px;
    text-align: center;

    flex: 0 0 240px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    border-radius: 5px;
    position: relative;
    background-size: cover;
    color: white;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
    transition: all 0.3s;

    .delete-button-container {
      cursor: auto;
      padding: 10px 10px 20px 20px;
      position: absolute;
      top: 0;
      right: 0;

      .delete-button {
        font-size: 20px;
        transition: all 0.3s;

        &:hover {
          cursor: pointer;
          transform: scale(1.4);
        }
      }
    }

    .project-name {
      position: absolute;
      bottom: 0;
      padding: 10px;
      padding-top: 30px;
      width: 100%;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.7));
      border-radius: 0 0 5px 5px;
      text-align: left;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.05);

      .thumbnail {
        animation: flashing 0.3s 0.25s;
        bottom: 10px;
      }
    }
  }

  .modal-warning-popup {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Dimmed background for focus */
    backdrop-filter: blur(5px); /* Soften the background content */
    z-index: 20; /* Ensure it's above other content */
  }

  .modal-warning-content {
    background-color: var(--color-primary);
    border: 2px solid var(--color-secondary);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .modal-warning-content p {
    margin-bottom: 20px;
    color: var(--color-secondary);
  }

  .modal-warning-content button {
    padding: 10px 15px; /* Matching the provided button styles */
    border: 0.5px solid var(--color-secondary);
    border-radius: 8px;
    background-color: transparent;
    color: var(--color-secondary); /* Text color against the accent background */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: var(--color-accent); /* Slightly darken on hover */
      color: var(--color-primary); /* Optionally lighten text color for contrast */
    }
  }

  .example-videos {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .example-video-thumbnails {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin: 10px 5vw;
    max-width: 1400px;
  }

  .example-video-thumbnails video {
    width: 200px;
    height: auto;
    cursor: pointer;
    border: 4px solid transparent;
    border-radius: 3px;
    transition: border-color 0.3s;

    &:hover {
      border-color: var(--color-accent);
    }

    &.selected {
      border-color: var(--color-accent);
    }

    @media (max-width: 720px) {
      margin-bottom: 10px;
    }
  }

  .example-videos button {
    margin-top: 10px;
    padding: 10px 15px;
    border: 0.5px solid var(--color-dark);
    border-radius: 8px;
    background-color: transparent;
    color: var(--color-dark);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: var(--color-accent);
      color: var(--color-primary);
    }

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  button:focus {
    outline: none;
  }

  .modal-overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 20;
  }

  .modal-content {
    background-color: var(--color-primary);
    border: 2px solid var(--color-secondary);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      margin-bottom: 20px;
      color: var(--color-cursor);
      font-weight: 500;
    }

    .button-wrapper {
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    button {
      padding: 10px 15px;
      border: 0.5px solid var(--color-secondary);
      border-radius: 8px;
      background-color: transparent;
      color: var(--color-secondary);
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: var(--color-accent);
        color: var(--color-primary);
      }
    }
  }
}
