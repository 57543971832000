/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Raleway', sans-serif;
  background-color: var(--color-tertiary);
  color: #e0e0e0;
  transition: all 0.3s ease;
  overflow-y: hidden;
}

h4 {
  font-size: 12px;
  font-weight: 600;
}

.editorContainer {
  display: flex;
  flex-direction: column;
  background-color: var(--color-tertiary);
  color: #e0e0e0;
  height: 100vh;
}

.topSection {
  display: flex;
  justify-content: center;
  width: 100%;
  border: solid 1px var(--color-secondary);
  max-height: 50vh;
}

.modalityOptionsContainer, .soundBoxContainer, .voiceBoxContainer, .musicBox {
  background-color: var(--color-primary);
  border-radius: var(--border-radius);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
}

.modalityOptionsContainer {
  flex: 0.1;
}

.soundBoxContainer {
  flex: 0.95;
}

.voiceBoxContainer {
  flex: 0.95;
  display: flex;
  flex-wrap: wrap;
}

.soundBox {
  flex: 1;
  padding: 0px;

  h3 {
    font-family: 'Raleway', sans-serif;
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: var(--color-cursor);
  }
}

.voiceBox {
  flex: 1;

  h3 {
    font-family: 'Raleway', sans-serif;
    text-align: left;
    font-weight: bold;
    font-size: 18px;
    color: var(--color-cursor);
    margin-bottom: 10px;
  }
}

.videoContainer {
  flex: 1;
  background-color: transparent;
  border-radius: var(--border-radius);
  overflow: hidden;
  position: relative;
}

.videoPlayer {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.timelineContainer {
  width: 100%;
  padding: 0px;
}

.action-button {
  border: 1px solid var(--color-secondary);
  background: none;
  padding: 14px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 14px;
  font-weight: bold;

  &:not(.no-hover):hover {
    background-color: var(--color-accent);
    color: var(--color-white);

    h2 {
      color: var(--color-white);

      .material-symbols-outlined {
        color: var(--color-white);
      }
    }
  }
}

.logo-box,
.faq-box {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  font-size: 14px;
  font-weight: bold;
  color: white;
}

.logo-box {
  background-color: var(--color-accent);
}

.faq-box {
  background-color: #2b2b2b;
  width: auto;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover,
  &:focus {
    background-color: #3d3d3d;
  }
}

.action-button,
.faq-box {
  &:focus {
    outline: 0.5px solid #cccccc;
    border-color: #cccccc;
  }
}

.logo-and-user-info {
  display: flex;
  align-items: center;

  .user-info {
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    align-items: flex-start;
  }

  .user-name {
    font-size: 14px;
    font-weight: bold;
    font-weight: 1500;
  }

  .membership-status {
    font-size: 10px;
  }
}

.feedback-button {
  position: fixed;
  bottom: 15px;
  right: -8px;
  background-color: #e18ca8c3;
  color: var(--color-primary);
  padding: 5px 15px;
  padding-right: 20px;
  border-radius: 50px 0 0 50px;
  cursor: pointer;
  z-index: 1000;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--color-accent);
  }
}

.p0 {
  padding: 0 !important;
}
