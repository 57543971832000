@import 'progress_bar.scss';

body {
  font-family: 'Raleway', sans-serif;
}

/* Style adjustments for headers */
h2 {
  font-family: 'Raleway', sans-serif; /* Ensures Roboto is used */
  text-align: center; /* Centers the text */
  font-weight: 500; /* Adjust weight as needed, 500 for medium */
  font-size: larger;
}

h3 {
  font-family: 'Raleway', sans-serif; /* Ensures Roboto is used */
  text-align: center; /* Centers the text */
  font-weight: 1000; /* Adjust weight as needed, 500 for medium */
  font-size: 16px;
  color: var(--color-cursor);
}

.text-style {
  font-family: 'Raleway', sans-serif; /* Ensures Raleway is used */
  color: var(--color-cursor); /* Text color */
  font-size: 14px; /* Text size */
  // Add other shared properties here
}

.soundBox {
  background-color: var(--color-primary);
  display: flex;
  flex-direction: row; // Change to column to stack children vertically
  height: 100%; // Ensure it takes full available height
  .promptSection,
  .generatedSoundSection {
    flex: 1; // Make sections flexible to fill available space evenly
  }

  .progressbar-container {
    width: 350px;
  }

  .defaultMessage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%; // Take the full width of the container
    text-align: center; // Ensure text inside is centered if needed

    .messageSentence {
      margin-top: 20px; // Add space above the sentence
      font-family: 'Raleway', sans-serif; // Example font, adjust as needed
      font-size: 16px; // Example font size, adjust as needed
      color: #959595; // Example text color, adjust as needed
    }
  }

  .promptSection {
    background-color: var(--color-four);
    width: 33.33%;
    display: flex;
    flex-direction: column;
  }

  .promptInputContainer {
    position: relative;
    margin-top: 10px;
    display: flex;
    align-items: center;
    outline: none;
    background-color: var(--color-four);
    padding-left: 15px;

    &:focus-within {
      border-color: var(--color-accent-hover);
      color: var(--color-primary);
    }
  }

  .promptInput {
    flex-grow: 1;
    border-radius: var(--border-radius);
    width: calc(100% - 30px);
    resize: none;
    padding: 10px 15px;
    background-color: var(--color-four);
    color: var(--color-cursor);
    border-top: 1px solid var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
    border-left: 1px solid var(--color-primary);
    // border-right: 2px solid var(--color-primary);
    font-size: 14px;
    height: 40px;
    line-height: 20px;
    padding-right: 50px;
    overflow-y: hidden;
  }

  .promptInput {
    &:focus {
      outline: none;
      color: var(--color-cursor);
      border-color: var(--color-sidebar);
      background-color: var(--color-primary);
      border-right: 1px solid transparent;
    }

    &:not(:focus):hover {
      background-color: var(--color-accent-hover);
      color: var(--color-primary);
    }
  }

  .promptInputContainer.activePrompt .promptInput {
    background-color: var(--color-primary);
    color: var(--color-cursor);
    outline: none;

    &:hover {
      background-color: var(--color-accent-hover);
      color: var(--color-primary);
    }
  }

  .promptInput,
  .effect {
    @extend .text-style;
  }

  .generateButton {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    transition: background-color 0.3s; // Smooth transition for hover effect
  }

  .generateButton:hover {
    background-color: #707070; // Color appears on hover
  }

  .adornosSuggestion {
    margin-top: 20px;
  }

  .effectsContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1; // Allows the container to expand and fill available space
    margin: 0; // Removes any default margin
    padding: 0; // Removes any default padding
    overflow: auto;
    padding-left: 15px;
  }

  .effect {
    flex: 1; // Distribute space evenly among effects
    background-color: var(--color-four);
    color: var(--color-cursor);
    text-align: center;
    padding: 25px 15px; // Adjust padding as needed but keep it uniform for equal height
    border-top: 1px solid var(--color-primary);
    border-bottom: 1px solid var(--color-primary);
    border-left: 1px solid var(--color-primary);
    text-align: left;

    &:first-child {
      border-bottom: none; // Removes border from the last element to avoid double lines
    }

    :hover {
      background-color: #707070; // Ensures hover effect is maintained
    }
  }

  .effect.active {
    background-color: var(--color-primary);
    border-top: 1px solid var(--color-sidebar);
    border-bottom: 1px solid var(--color-sidebar);
    border-left: 1px solid var(--color-sidebar);
  }

  .effect:hover {
    background-color: var(--color-accent-hover);
    color: var(--color-primary);
  }

  .effect.active,
  .generateButton.active {
    background-color: var(--color-primary); /* Or any other way you prefer to highlight */
    color: var(--color-cursor); /* Adjust text color for better contrast if necessary */
  }

  .promptInputContainer.activePrompt .promptInput {
    background-color: var(--color-primary);
    color: var(--color-cursor);
    border-top: 1px solid var(--color-sidebar);
    border-bottom: 1px solid var(--color-sidebar);
    border-left: 1px solid var(--color-sidebar);

    &:hover {
      background-color: var(--color-accent-hover);
      color: var(--color-primary);
    }
  }

  .emptySection {
    width: 66.66%;
  }

  .generatedSoundSection {
    position: relative;
    width: 66.66%;
    background-color: var(--color-primary);
  }

  // Loadbar styling

  .progressBarOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }

  .loadingTextWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .loadingText {
    margin-bottom: 50px; // Space above the progress bar
    color: var(--color-sidebar); // Adjusted for visibility against the overlay background
    font-size: 1.1rem; // Example size, adjust as needed
    text-align: center;
  }

  .soundLoadingOverlay {
    position: absolute;
    margin-top: 30px;
    border-radius: 8px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: rgba(54, 55, 56, 0.8);
    margin-left: 20px;
  }

  // Ensure the ProgressBar2 container fits properly
  .progressBar1,
  .progressBar2 {
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .progressBar1,
  .progressBarOverlay {
    justify-content: center;
    align-items: center;
  }

  .progressBar1 .progress,
  .progressBar1 .progress-bar {
    width: 100%; // Ensure the progress bar fills its container
    justify-content: center;
    align-items: center;
  }

  .progressBar2 .progress,
  .progressBar2 .progress-bar {
    width: 100%; // Ensure the progress bar fills its container
    justify-content: center;
    align-items: center;
  }

  .progressBar2,
  .soundLoadingOverlay {
    justify-content: center;
    align-items: center;
    // background-color: rgba(54, 55, 56, 0.8);
  }
}


.soundContainers {
  display: flex;
  flex-direction: column;
  max-height: 280px; // Assuming each box is approximately 60px in height, adjust as necessary
  overflow-y: auto; // Enables vertical scrolling
  margin-right: 0px; /* Adjust margin to pull content and scrollbar to the left */
  position: relative;
}

.soundContainer {
  display: flex;
  align-items: center;
  margin-top: 10px;
  background-color: var(--color-light-grey);
  padding: 10px;
  border-radius: 8px;
  position: relative;

  .waveformContainer {
    flex-grow: 1;
    background-color: var(--color-primary); // Placeholder color for the waveform display
    height: 40px;
    border-radius: 8px;
    margin: 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30vh;

    div {
      width: 100%;
    }
  }
}

.playButton,
.refreshButton,
.garbageButton,
.plusButton {
  margin-right: 5px;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  outline: none;
  border-radius: 50%; // Making buttons circular or pill-shaped based on design preference
}

.playButton,
.refreshButton,
.garbageButton,
.plusButton {
  background: transparent; // Removes any background color
  border: none; // Removes the border
  cursor: pointer;
  width: auto; // Adjust based on your image size
  height: auto; // Adjust based on your image size
  padding: 0; // Removes padding, adjust as necessary
  outline: none;

  img {
    width: 24px; // Adjust based on your needs
    height: 24px; // Adjust based on your needs
  }
}

.playButton img,
.refreshButton img,
.garbageButton img,
.plusButton img {
  transition: filter 0.3s ease; // Smooth transition for the filter effect
}

.playButton:hover img,
.refreshButton:hover img,
.garbageButton:hover img,
.plusButton:hover img {
  border: none;
  outline: none;
  filter: invert(32%) sepia(35%) saturate(300%) hue-rotate(280deg) brightness(95%) contrast(90%);
}

.garbageButton:focus,
.plusButton:focus,
.playButton:focus {
  outline: none;
}
