@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Define your global variables here */
  --color-primary: #f1efed;
  --color-secondary: #b0a1a1;
  --color-tertiary: #d2d2d2;
  --color-four: #e3e2e2;
  --color-accent: #e18ca8;
  --color-accent-hover: #e18ca8a7;
  --color-accent-bright: #ff99b9;
  --color-dark: #504545;
  --color-white: #f8f5f5;
  --color-light-grey: #dcdcdc;
  --color-cursor: #6e6e6e;
  --color-sidebar: #b0b0b0;
  --color-modal-overaly: rgba(0, 0, 0, 0.35);
  --color-voice: #a7c1d7;
  --color-voice-accent: #508cc8;
  --color-music: #daa691;
  --color-music-accent: #cb937d;

  --border-radius: 2px;

  --border-width: 1px;
  --border-color: #dee2e6;

  /* You can add more variables as needed */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Raleway', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.centered-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensure full viewport height */
}
