@import url(https://fonts.googleapis.com/css?family=Open+Sans:600,400,300);

body {
  font-family: 'Raleway', sans-serif;
}

.show-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 350px;
}

.rounded {
  height: 100%;
  border-radius: 999px;
  overflow: hidden;
  transform: translateZ(0);
}

.progress-container {
  position: relative;
}

.progress {
  position: relative;
  width: 100%;
  height: 20px;
  background-color: var(--color-light-grey);
  border-radius: 99px;

  .progress-bar {
    position: relative;
    height: 100%;
    background-color: var(--color-accent);
    transition: width .2s linear;
  }

  .progress-number {
    position: absolute;
    left: 50%;
    top: -30px; // Adjusted to move the text above the progress bar
    transform: translateX(-50%);
    transition: left 0.3s;

    &:before {
      content: "";
      position: absolute;
      top: 20px; // Adjust so the arrow points down to the bar
      left: 50%;
      transform: translateX(-50%);
      border-top: 5px solid var(--color-cursor);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
    }

    &:after {
      content: attr(aria-valuenow) '%'; // Dynamically insert the progress value
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0; // Position directly above the ::before element
      color: #fff;
      font-size: 13px;
      font-weight: 300;
      background-color: var(--color-cursor);
      padding: 4px 10px;
      border-radius: 3px;
    }
  }
}

.button-container {
  display: block;
  text-align: center;
  padding: 60px 0;
}

.button {
  color: #1F2225;
  font-weight: 400;
  text-decoration: none;
  border: 2px solid var(--color-cursor);
  padding: 10px 25px;
  transition: all .15s ease-in-out;

  &:hover {
    background-color: var(--color-cursor);
    color: #fff;
  }
}
