/*    HIDE VIDEO CONTROLS    */

video::-webkit-media-controls-fullscreen-button {
  display: none;
}

video::-webkit-media-controls-toggle-closed-captions-button {
  display: None;
}

.videoContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  video.videoPlayer {
    max-width: 100%;
    margin: auto;
    max-height: 60vh;
    border-radius: 0;
  }

  .videoControls {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50px; /* Set a constant height for the controls */
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0.5;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0px;
    // padding: 10px;
    box-sizing: border-box;
    transition: opacity 0.3s ease;

    &:hover,
    &:focus,
    &:active {
      opacity: 0.9;
    }

    .time-duration,
    button.play-pause,
    button.full-screen {
      padding: 0 20px; /* Apply padding here for these elements, not affecting seekBar */
    }

    .time-duration {
      position: absolute;
      left: 0; /* Align with the edge, padding applied internally */
    }

    button.play-pause {
      position: absolute;
      left: 50%; /* Horizontally center the button */
      top: 50%; /* Vertically center the button within the .videoControls */
      transform: translate(
        -50%,
        -50%
      ); /* Adjust for exact centering both horizontally and vertically */
    }

    button.play-pause,
    button.full-screen {
      position: absolute;
      outline: none; /* Remove the outline/border when focused or clicked */
      &:focus {
        outline: none;
      }
    }

    button.full-screen {
      position: absolute;
      right: 0; /* Keep existing positioning */
      background: none; /* Remove any default background */
      border: none; /* Remove any default border */
      outline: none; /* Ensure no outline on focus for consistency */
      cursor: pointer; /* Indicate clickable action */

      img {
        height: 30px; /* Adjust based on your icon's size for consistency */
        width: auto; /* Maintain aspect ratio */
      }
    }

    .seekBar {
      position: absolute;
      top: 0; /* Keep the seekBar at the very top */
      -webkit-appearance: none;
      width: calc(100% - 0px); /* Ensure it spans the full width without padding */
      height: 4px;
      background: #fff;
      cursor: pointer;
      border-radius: var(--border-radius);
    }

    /* Custom styles for the thumb of the seekBar */
    .seekBar::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      background: #ffffff;
      cursor: pointer;
      border-radius: 50%;
    }

    .seekBar::-moz-range-thumb {
      width: 12px;
      height: 12px;
      background: #ffffff;
      cursor: pointer;
      border-radius: 50%;
    }
  }
}
