/* Basic Reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  transition: all 0.3s ease;
}

h2 {
  font-size: 24px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);

  span.material-symbols-outlined {
    position: relative;
    top: 6px;
    padding: 0 3px;
  }
}


.modal-overlay {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  z-index: 20;
  padding: 10px;

  .download-in-progress {
    color: var(--color-secondary);
    padding: 20px;
    border: 1px solid var(--color-secondary);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .download-message {
    margin-bottom: 15px;
    font-weight: bold;
  }
}

.modal-content {
  background-color: var(--color-primary);
  border: 2px solid var(--color-secondary);
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--color-dark);
  box-shadow: rgba(0, 0, 0, 0.05) 0 5px 10px;


  .button-wrapper {
    display: flex;
    justify-content: center;
    gap: 10px;

    button {
      padding: 10px 20px;
      cursor: pointer;
      border: none;
      background-color: transparent;
      font-size: 16px;
      transition: color 0.3s;
      border: 1px solid var(--color-secondary);
      border-radius: 8px;

      &:hover {
        background-color: var(--color-accent);
        color: var(--color-primary);
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.show-project-controls {
  .logo-image {
    opacity: 0.55;
  }
}

.header:not(.show-project-controls) {
  padding: 10px;
  padding-bottom: 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background-color: var(--color-primary);
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

  .header-left,
  .header-right {
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    gap: 0px;
  }

  .header-center {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .logo-image {
    height: 40px;
    width: auto;
  }

  .header-left {
    padding: 9px; // this is a little iffy, but works
  }

  .action-button {
    border-top: 0;
    border-bottom: 0;
    border-left: 1px solid var(--color-secondary);
    border-right: 1px solid var(--color-secondary);
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
  }

  .logo-box,
  .faq-box {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    font-size: 14px;
    font-weight: bold;
    color: white;

    &.logo-box {
      background-color: var(--color-accent);
      width: 40px;
      height: 40px;
    }

    &.faq-box {
      background-color: #2b2b2b;
      padding: 10px 20px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover,
      &:focus {
        background-color: #3d3d3d;
      }
    }
  }

  .logo-and-user-info {
    display: flex;
    align-items: center;

    .user-info {
      display: flex;
      flex-direction: column;
      margin-left: 8px;
      align-items: flex-start;

      .user-name,
      .membership-status {
        font-weight: bold;
      }

      .user-name {
        font-size: 14px;
      }

      .membership-status {
        font-size: 10px;
      }
    }
  }

  .new-project-message {
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;

    input[type='text'] {
      padding: 12px;
      border: 1px solid var(--color-secondary);
      border-radius: 8px;
      font-size: 16px;
      outline: none;
      transition: border-color 0.3s ease;
      width: 100%;
      margin-top: 10px;

      &:focus {
        border-color: var(--color-accent);
      }
    }
  }


  .export-option {
    padding: 15px;
    border: 1px solid var(--color-secondary);
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    text-align: center;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--color-accent);
      color: var(--color-primary);
    }

    &.selected {
      background-color: var(--color-accent);
    }
  }

  .profile-dropdown {
    background: var(--color-primary);
    color: black;
    cursor: pointer;
    z-index: 4;

    position: absolute;
    min-width: 160px;
    top: 100%;
    //top: calc(100% + 1px);
    border: 1px solid var(--color-secondary);

    overflow-y: hidden;
    display: none;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  }

  .profile-dropdown:hover, .header-left:hover + .profile-dropdown {
    display: block;
  }

  .back-button {
    padding-right: 10px;
    transition: all 0.3s;


    .material-symbols-outlined {
      position: relative;
      top: 7px;
      color: black;
    }

    &:hover {
      transform: scale(1.1, 1.1);
      margin-right: 10px;
    }
  }
}
