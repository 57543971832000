// src/pages/editor/musicModal.scss
.music-modal {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;

  .overlay {
    position: absolute;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
  }

  .modal-content {
    background-color: var(--color-white);
    border-radius: 0.5rem;
    padding: 2rem;
    z-index: 10;
    min-width: 600px;

    h2 {
      font-size: 1.5rem;
      font-weight: bold;
      margin-bottom: 1rem;
    }

    .sound-container {
      display: flex;
      justify-content: center;
      margin-bottom: 1.5rem;
    }

    .soundContainer {
      width: 100%;
    }

    .actions {
      display: flex;
      justify-content: center;

      button {
        padding: 0.5rem 1rem;
        border-radius: 0.25rem;
        font-size: 1rem;
        cursor: pointer;

        &.cancel {
          background-color: var(--color-sidebar);
          margin-right: 1rem;
        }

        &.add {
          background-color: var(--color-sidebar);
        }

        &:hover {
          background-color: var(--color-accent);
        }
      }
    }
  }

  .soundContainer {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 5px;
    margin-bottom: 10px;

    .playButton {
      cursor: pointer;
      margin-right: 10px;

      img {
        width: 24px;
        height: 24px;
      }
    }

    .waveformContainer {
      flex: 1;
    }

    .plusButton {
      cursor: pointer;
      margin-left: 10px;

      .addSoundPlus {
        width: 20px;
        height: 20px;
      }
    }
  }

}
