.text-style {
  font-family: 'Raleway', sans-serif;
  color: var(--color-cursor);
  font-size: 14px;
}

.musicBox {
  display: flex;
  flex-direction: row;
  width: 100%;

  .column {
    //width: 50%;
    padding: 20px;
  }

  .promptSection {
    flex: 1;
    background-color: var(--color-four);
    border: 1px solid var(--color-border);

    .voiceTextArea {
      flex: 1;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
      height: 50%;

      textarea {
        @extend .text-style;
        flex: 1;
        border: 1px solid var(--color-border);
        border-radius: 4px;
        padding: 8px;
        resize: none;
        width: 100%;

        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }

    button {
      float: right;
    }
  }

  .resultSection {
    flex: 2;
    overflow-y: scroll;
  }


  button {
    @extend .text-style;
    background-color: var(--color-sidebar);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: var(--color-accent);
    }

    &:disabled {
      background-color: var(--color-light-grey);
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  audio {
    margin-top: 10px;
    width: 100%;
  }

  .voiceLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px;
    color: var(--color-cursor);
  }

  .voicePlayer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    audio {
      flex: 1;
      margin-right: 10px;
    }

    .addToTimelineButton {
      @extend .text-style;
      background-color: var(--color-sidebar);
      color: #fff;
      border: none;
      border-radius: 20px;
      padding: 8px 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: var(--color-accent);
      }
    }
  }
}
