body:has(.account-page) {
  background: var(--color-primary);
}

.account-page {
  .header {
    box-shadow: none;
  }

  .account-page-container {
    text-align: left;
    position: relative;
    color: var(--color-dark);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 10vh;

    .account-card {
      background: white;
      padding: 50px;
      border-radius: 10px;
      box-shadow: 1px 5px 10px rgba(0, 0, 0, 0.1);

      .heading {
        font-size: 24px;
        font-weight: bold;
      }

      table tr * {
        padding: 10px;
      }
    }

  }

  button {
    padding: 10px 15px;
    border: 0.5px solid var(--color-secondary);
    border-radius: 8px;
    background-color: transparent;
    color: var(--color-secondary);
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: var(--color-accent);
      color: var(--color-primary);
    }
  }

  .account-header {
    padding: 30px;
    z-index: 10;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .material-symbols-outlined {
      position: relative;
      top: 7px;
      color: black;
      transition: all 0.3s;
    }

    a:hover {
      .material-symbols-outlined {
        color: var(--color-accent);
        transform: scale(1.2, 1.4);
        margin-right: 10px;
      }
    }

    img {
      height: 50px; // Set the logo height to 50px
      width: auto; // Maintain aspect ratio of the logo
    }
  }

}
