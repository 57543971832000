// projectChatModal.scss
.project-chat-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.project-chat-modal-content {
  background-color: var(--color-white);
  padding: 20px;
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  width: 80%;
  height: 80%;

  .chat-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    position: relative;

    .comment-list {
      flex: 1;
      overflow-y: auto;
      margin-bottom: 10px;

      .comment {
        background-color: var(--color-primary);
        padding: 15px;
        border-radius: 8px;
        margin-bottom: 15px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        padding-right: 10px;
        position: relative;
        width: 100%;

        .comment-content {
          flex: 1;
          margin-bottom: 10px;
          width: 100%;

          .comment-header {
            display: flex;
            align-items: center;
            margin-bottom: 5px;
            flex-direction: row;
            width: 100%;

            .user-icon {
              width: 24px;
              height: 24px;
              background-color: var(--color-accent);
              color: var(--color-primary);
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 10px;

              i {
                font-size: 12px;
              }
            }

            .comment-user {
              font-weight: bold;
              margin-right: 10px;
              color: var(--color-cursor);
            }

            .comment-info {
              display: flex;
              align-items: center;
              margin-left: 10px;
              flex-grow: 1;
              justify-content: space-between;
            }

            .comment-timestamp {
              font-size: 12px;
              color: var(--color-cursor);
              white-space: nowrap;
            }
          }

          .comment-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            margin-bottom: 10px;
            background-color: var(--color-white);
            padding: 8px;
            border-radius: 6px;
            border: 1px solid var(--color-tertiary);
            color: var(--color-cursor);

            .comment-actions {
              display: flex;
              align-items: center;
              margin-left: 10px;

              .action-button {
                display: flex;
                align-items: center;
                background: none;
                border: none;
                cursor: pointer;
                padding: 4px;
                margin-left: 4px;
                color: var(--color-cursor);
                transition: color 0.3s ease;

                &:hover {
                  color: var(--color-accent);
                }

                i {
                  font-size: 18px;
                }
              }
            }
          }
        }

        .reply {
          margin-left: 40px;
          margin-top: 15px;
          padding: 15px 15px 0 15px;
          background-color: var(--color-sidebar);
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
          margin-left: auto;
          margin-top: 15px;
          width: calc(100% - 40px);
          box-sizing: border-box;

          .comment-content {
            .comment-header {
              .user-icon {
                background-color: var(--color-tertiary);
              }
            }
          }
        }
      }
    }

    form {
      display: flex;

      input {
        flex: 1;
        padding: 8px;
        font-size: 16px;
        border: 1px solid var(--color-tertiary);
        border-radius: 4px;
        background-color: var(--color-primary);
        color: var(--color-cursor);
        outline: none;

        &::placeholder {
          color: var(--color-placeholder);
        }

        &:focus {
          border-color: var(--color-accent);
        }
      }

      button {
        padding: 8px 16px;
        font-size: 14px;
        font-weight: bold;
        background-color: var(--color-accent);
        color: var(--color-primary);
        border: none;
        border-radius: 6px;
        cursor: pointer;
        transition: background-color 0.3s ease;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        margin-left: 10px;

        &:hover {
          background-color: var(--color-accent-hover);
          transform: translateY(-2px);
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        &:active {
          transform: translateY(0);
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        &:focus {
          outline: none;
          box-shadow: 0 0 0 3px rgba(var(--color-accent-rgb), 0.3);
        }
      }
    }
  }

  .close-button {
    position: absolute;
    top: 20px;
    right: 20px;
    padding: 8px 16px;
    font-size: 14px;
    font-weight: bold;
    background-color: var(--color-primary);
    color: var(--color-cursor);
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

    &:hover {
      background-color: var(--color-accent);
      color: var(--color-primary);
      transform: translateY(-2px);
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }

    &:active {
      transform: translateY(0);
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px rgba(var(--color-accent-rgb), 0.3);
    }
  }
}
