/* Override intro.js tooltip box */
.introjs-tooltip {
  background-color: var(--color-primary) !important; /* Match your app's theme */
  color: var(--color-cursor) !important; /* Text color */
  border-radius: 8px !important; /* Match your app's styling */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1) !important; /* Soft shadow for depth */
}

/* Override intro.js tooltip buttons */
.introjs-button {
  background-color: var(--color-sidebar) !important; /* Button background color */
  color: var(--color-primary) !important; /* Button text color */
  border: none !important; /* Remove default border */
  border-radius: 8px !important; /* Rounded corners */
  padding: 10px 30px !important; /* Button padding */
  font-size: 14px !important; /* Text size */
  font-weight: bold !important; /* Text weight */
  transition: background-color 0.3s !important; /* Smooth transition */
  content: none !important;
  outline: none !important;
  text-shadow: none !important;
}

/* Centering text within introjs-tooltiptext */
.introjs-tooltiptext {
  text-align: center !important; /* Horizontally centers the text */
  display: flex !important;
  justify-content: center !important; /* Centers content horizontally for flex items */
  align-items: center !important; /* Centers content vertically for flex items */
  flex-direction: column !important; /* Ensures the text is centered vertically in a column layout */
  height: 100%; /* Ensures the container takes full height, adjust as necessary */
}

.introjs-nextbutton,
.introjs-prevbutton {
  border: none !important; /* Ensure no border is applied */
  outline: none !important; /* Remove focus outline */
}

/* Hover state for intro.js buttons */
.introjs-button:hover {
  background-color: var(--color-accent) !important; /* Darker shade on hover */
}

.introjs-button:focus {
  border: none !important;
  outline: none !important; /* Remove default focus outline */
  box-shadow: inset 0 0 0 2px #3d3d3d !important; /* Optional: custom focus style */
}

/* Reinforcing no border or outline on focus for next and back buttons */
.introjs-nextbutton:focus,
.introjs-prevbutton:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important; // In case a box-shadow is being used as a border substitute
}

/* Additionally, ensure no outline or border for all buttons on focus */
.introjs-button:focus {
  border: none !important;
  outline: none !important;
  box-shadow: none !important; // Again, to cover the use of box-shadow
}


/* Override the skip button to match the action buttons */
.introjs-skipbutton {
  background-color: var(--color-primary) !important; /* Match skip button with action buttons */
  color: var(--color-sidebar)  !important;
  font-size: 14px !important;
  margin-right: 10px !important; /* Optional: add a gap between skip and action buttons */
}

/* Specific styling for highlight area */
.introjs-helperLayer {
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.4) !important; /* Creating a focus effect */
}

/* Highlighted element outline */
.introjs-highlightedElement {
  border: 2px solid var(--color-accent) !important; /* Border color around highlighted element */
}

/* Attempt to visually hide duplicated button text */
.introjs-button::after {
  content: none !important;
}

/* Override introjs-arrow to be always grey */
.introjs-arrow {
  border-color: transparent !important;
}

.introjs-arrow.top {
  border-bottom-color: var(--color-primary) !important;
}

.introjs-arrow.right {
  border-left-color: var(--color-primary) !important;
}

.introjs-arrow.bottom {
  border-top-color: var(--color-primary) !important;
}

.introjs-arrow.left {
  border-right-color: var(--color-primary) !important;
}
