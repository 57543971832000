// storyBlock.scss
$border-radius: 8px;
$width: 300px;
$height: 172.5px; // 16:9, but by trail and error

.story-block {
  text-align: center;
  margin-bottom: 30px;
  width: $width;
  height: $height;
  border-radius: $border-radius;
  overflow: hidden;
  position: relative;

  .file-dropzone {
    width: 100%;
    height: 100%;
    border: 2.5px dashed var(--color-tertiary);
    border-radius: $border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding: 20px;

    .plus-sign {
      font-size: 80px;
      color: var(--color-tertiary);
    }

    &:hover {
      border-color: var(--color-accent);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-accent);
        opacity: 0.2; // Adjust opacity to desired level
        pointer-events: none; // Ensure the overlay doesn't interfere with drop
      }

      .plus-sign {
        color: var(--color-accent);
      }
    }

    &.dragging {
      border-color: var(--color-accent);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: var(--color-accent);
        opacity: 0.2; // Adjust opacity to desired level
        pointer-events: none; // Ensure the overlay doesn't interfere with drop
      }
    }
  }

  .loading-container {
    padding: 20px;
    padding-top: 80px;
    width: 100%;
    height: 100%;
  }

  .video-container {
    width: 100%;
    height: 100%;
    background: black;

    video {
      height: 100%;

      &::-webkit-media-controls {
        display: none !important;
      }

      &::-webkit-media-controls-enclosure {
        display: none !important;
      }
    }

    &:hover video {
      filter: blur(2px);
    }

    .center-container {
      position: absolute;
      z-index: 10;
      width: 100%;
      height: 100%;
      align-content: center;

      .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
        margin-bottom: 20px;
        padding-right: 20px;
        padding-left: 10px;

        .open-button {
          width: calc($width / 2);
          padding: 10px;
          border-radius: $border-radius;
          margin: auto;
          background: white;
          display: none;
          cursor: pointer;
          color: var(--color-accent); // Add color for text
          font-weight: bold; // Make text bold
          transition: all 0.3s ease; // Add transition for smooth effect

          &:hover {
            background-color: var(--color-accent); // Change background color on hover
            color: var(--color-primary); // Change text color on hover
          }
        }
      }

      &:hover .open-button {
        display: block;
      }
    }
  }

  .modal-warning-popup {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* Dimmed background for focus */
    backdrop-filter: blur(5px); /* Soften the background content */
    z-index: 20; /* Ensure it's above other content */
  }

  .modal-warning-content {
    background-color: var(--color-primary);
    border: 2px solid var(--color-secondary);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .modal-warning-content p {
    margin-bottom: 20px;
    color: var(--color-secondary);
  }

  .modal-warning-content button {
    padding: 10px 15px; /* Matching the provided button styles */
    border: 0.5px solid var(--color-secondary);
    border-radius: 8px;
    background-color: transparent;
    color: var(--color-secondary); /* Text color against the accent background */
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;

    &:hover {
      background-color: var(--color-accent); /* Slightly darken on hover */
      color: var(--color-primary); /* Optionally lighten text color for contrast */
    }
  }

  button:focus {
    outline: none;
  }

  .modal-overlay {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(5px);
    z-index: 20;
  }

  .modal-content {
    background-color: var(--color-primary);
    border: 2px solid var(--color-secondary);
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 20px;

    p {
      margin-bottom: 20px;
      color: var(--color-cursor);
      font-weight: 500;
    }

    .button-wrapper {
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    button {
      padding: 10px 15px;
      border: 0.5px solid var(--color-secondary);
      border-radius: 8px;
      background-color: transparent;
      color: var(--color-secondary);
      cursor: pointer;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: var(--color-accent);
        color: var(--color-primary);
      }
    }
  }
}
