// style.scss
.modalityOptions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 100%;
  padding: 10px 0;
}

.modalityLogo {
  width: 40px;
  height: 40px;
  object-fit: contain;
  background-color: var(--color-sidebar);
  border-radius: 10px;
  padding: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  cursor: pointer;
}

.modalityLogo:hover {
  background-color: var(--color-accent);
}

.modalityLogo.selected {
  background-color: var(--color-accent);
}

.comingSoonPopup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.popupContent {
  background-color: white;
  padding: 30px;
  border-radius: 10px;
  text-align: center;
  max-width: 400px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.popupContent h2 {
  color: var(--color-accent);
  font-size: 24px;
  margin-bottom: 10px;
}

.popupContent p {
  color: var(--color-cursor);
  font-size: 16px;
  margin-bottom: 20px;
}

.closeButton {
  background-color: var(--color-accent);
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  outline: none;
}

.closeButton:hover {
  background-color: var(--color-accent-hover);
}

.closeButton:focus {
  outline: none;
}
