:root {
  --timeline: var(--color-primary);
  --background: var(--color-light-grey);
}

.timeline-editor-engine {
  flex: 1; /* Allows the timeline editor to expand and fill the available space */
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: var(--background);
  border-radius: var(--border-radius);
  overflow: none;

  & .timeline-list {
    width: 150px;
    margin-top: 42px;
    flex: 0 1 auto;
    overflow: overlay;
    padding: 0 10px;

    &-item {
      height: 28px;
      padding: 2px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 8px;

      & .text {
        color: #fff;
        height: 28px;
        width: calc(100% - 20px);
        height: 100%;
        padding: 0 10px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        background-color: #333;
      }
    }

    .zoom-icon {
      position: absolute;
      top: 10px; // Adjust the top position to position it above the timeline-list
      left: 10px;
      z-index: 1;

      img {
        width: 18px;
        height: 18px;
      }
    }

    .seekBar {
      position: absolute; /* Position the cursor absolutely within the timeline list */
      z-index: 2; /* Ensure it's above other items in the list */
      top: 0; /* Adjust as necessary */
      cursor: pointer;
      left: 30px; // Adjust the left position to start 20px further to the right
      width: 110px;
    }

    .seekBar::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      background: #ffffff;
      cursor: pointer;
      border-radius: 50%;
    }

    .seekBar::-moz-range-thumb {
      width: 12px;
      height: 12px;
      background: #ffffff;
      cursor: pointer;
      border-radius: 50%;
    }
  }

  .timeline-editor {
    background-color: var(--background);
    flex: 1 1 auto;

    &-action {
      height: 28px !important;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .timeline-list-item,
  .timeline-editor-action {
    height: 28px; // Ensure consistent height
    display: flex;
    align-items: center; // Vertically center content
    justify-content: center; // Horizontally center content (if applicable)
    margin-bottom: 5px; // Consistent spacing between items
    background-color: var(--color-sidebar);
  }

  .timeline-list-item {
    padding: 2px 5px; // Consistent padding
  }
}

.prompt-ribbon {
  z-index: 2;
}

.timeline-editor-action:has(> .audio-ribbon) {
  z-index: 1;
}

.prompt-ribbon.placeholder-ribbon {
  z-index: 0;
}

.timeline-editor-action:has(> div.placeholder-ribbon) {
  opacity: 0;
}

.timeline-editor-action:has(> div.prompt-ribbon) {
  background: #aaa;
  // this prevents other placeholder ribbons from firing
  z-index: 10;
  display: block;
}

.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fff;
}

.promptBox {
  width: 80%;
  min-width: 275px;
  min-height: 55px;
  background-color: white;
  color: black;
  z-index: 99;
  position: relative;
  bottom: 58px;
  left: calc(20% - 30px);
  background: white;
  padding: 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;

  label {
    flex-grow: 1;

    input {
      width: 100%;
      outline: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  input[type='submit'],
  button {
    flex-shrink: 0;
    background-color: #ccc;
    transition: background-color 0.3s, color 0.3s;
    outline: none;

    &:hover {
      background-color: var(--color-accent);
      color: white;
    }

    &:focus {
      outline: none;
    }
  }
}

.prompt-box-below {
  bottom: -24px !important;
}

.promptBoxConnector {
  width: 20px;
  height: 10px;
  z-index: 99;
  position: relative;
  bottom: 58px;
  left: calc(30% - 5px);
}

.timeline-editor-engine .player-panel {
  width: 100%;
  /* max-width: 800px; */
  // height: 0px;
  position: relative;
}

.timeline-editor-engine .player-panel .lottie-ani {
  position: absolute;
  width: 100%;
  // height: 100%;
  left: 0;
  top: 0;
}

.timeline-editor-engine .timeline-editor {
  width: 100%;
}

.timeline-editor-engine .timeline-editor-action {
  height: 28px !important;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 4px;
}

.timeline-editor-engine .timeline-editor-action-effect-audio,
.timeline-editor-engine .timeline-editor-action-effect-video {
  cursor: pointer;
  background-position: bottom;
  background-repeat: repeat-x;
}

.timeline-editor-engine .timeline-editor-action-effect-audio .audio,
.timeline-editor-engine .timeline-editor-action-effect-video .video {
  width: 100%;
  height: 100%;
  font-size: 12px;
  color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.timeline-editor-engine .ribbon-text {
  margin-left: 4px;
  flex: 1 1 auto;
  text-align: center;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
}

.timeline-editor-engine * .timeline-editor-action-left-stretch::after {
  border-left: 7px solid rgba(255, 255, 255, 0.4);
}

.timeline-editor-engine * .timeline-editor-action-right-stretch::after {
  border-right: 7px solid rgba(255, 255, 255, 0.4);
}

.timeline-player {
  height: 32px;
  width: 100%;
  max-width: 800px;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #ddd;
}

.timeline-player .play-control {
  width: 24px;
  height: 24px;
  border-radius: 4px;
  display: flex;
  background-color: #666;
  justify-content: center;
  align-items: center;
}

.timeline-player .time {
  font-size: 15px;
  margin: 0 20px;
  width: 70px;
}

.timeline-player .rate-control {
  justify-self: flex-end;
}

.timeline-player .rate-control .ant-select {
  width: 90px !important;
}

.timeline-player .rate-control .ant-select .ant-select-selector {
  border: none;
  box-shadow: none !important;
  background-color: transparent;
  color: #ddd;
}

.timeline-player .rate-control .ant-select .ant-select-selector .ant-select-selection-item {
  font-size: 15px;
  display: flex;
  justify-content: center;
}

.timeline-player .rate-control .ant-select-arrow {
  color: #ddd;
}

.timeline-list-item {
  height: 28px;
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background-color: var(--color-sidebar);

  & .icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  & img {
    width: 18px;
    height: auto;
    cursor: pointer;
  }

  & .volume-slider {
    width: 100px;
    margin-left: 20px;

    .ant-slider {
      .ant-slider-track {
        background-color: var(--color-primary);
      }

      .ant-slider-handle,
      .ant-slider-handle-dragging.ant-tooltip-open {
        width: 10px;
        height: 10px;
        border: none;
        box-shadow: none;
        border-radius: 50%;
        cursor: pointer;

        &:focus {
          box-shadow: none;
        }

        &::after {
          content: '';
          position: absolute;
          inset-block-start: 0;
          inset-inline-start: 0;
          width: 10px;
          height: 10px;
          background-color: #ffffff;
          box-shadow: none;
          border-radius: 50%;
          cursor: pointer;
          transition: inset-inline-start 0.2s, inset-block-start 0.2s, width 0.2s, height 0.2s,
          box-shadow 0.2s;
        }
      }
    }
  }
}

.timeline-editor-engine .scale-adjuster {
  width: calc(100% - 10px); /* Adjusted width to account for padding */
  margin: 16px 0px;
  border-radius: 4px;
  background-color: var(--color-sidebar);
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-editor-engine .scale-adjuster input[type='range'] {
  -webkit-appearance: none; /* Override default appearance for Chrome, Safari */
  width: 100%; /* Full width to match container */
  background-color: transparent; /* Ensure no background */
}

/* Chrome, Safari, Opera styles */
.timeline-editor-engine .scale-adjuster input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none; /* Remove default styling */
  appearance: none;
  width: 12px; /* Adjust thumb size */
  height: 12px; /* Adjust thumb size */
  background: var(--color-cursor); /* Thumb color, adjusted to match seekBar style */
  cursor: pointer;
  border: none; /* Remove any border */
  border-radius: 50%; /* Circular shape */
  box-shadow: none; /* Optional: Remove or adjust shadow if needed */
  margin-top: -4px; /* Adjust vertical alignment if necessary */
}

/* Firefox styles */
.timeline-editor-engine .scale-adjuster input[type='range']::-moz-range-thumb {
  width: 12px; /* Adjust thumb size */
  height: 12px; /* Adjust thumb size */
  background: var(--color-cursor); /* Thumb color, adjusted to match seekBar style */
  cursor: pointer;
  border: none; /* Remove any border */
  border-radius: 50%; /* Circular shape */
}

/* IE, Edge styles */
.timeline-editor-engine .scale-adjuster input[type='range']::-ms-thumb {
  width: 12px; /* Adjust thumb size */
  height: 12px; /* Adjust thumb size */
  background: var(--color-cursor); /* Thumb color, adjusted to match seekBar style */
  cursor: pointer;
  border-radius: 50%; /* Circular shape */
}

/* Adjust track styles for Chrome, Safari, Opera */
.timeline-editor-engine .scale-adjuster input[type='range']::-webkit-slider-runnable-track {
  background: var(--color-sidebar); /* Track color */
  border-radius: 4px; /* Rounded corners for the track */
  height: 4px; /* Adjust track height */
}

/* Adjust track styles for Firefox */
.timeline-editor-engine .scale-adjuster input[type='range']::-moz-range-track {
  background: var(--color-sidebar); /* Track color */
  border-radius: 4px; /* Rounded corners for the track */
  height: 4px; /* Adjust track height */
}

/* Adjust track styles for IE, Edge */
.timeline-editor-engine .scale-adjuster input[type='range']::-ms-track {
  background: var(--color-sidebar); /* Track color */
  border-radius: 4px; /* Rounded corners for the track */
  height: 4px; /* Adjust track height */
  border: none; /* Remove border */
}

.timeline-editor-engine .timeline-editor-action-effect-audio {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.timeline-editor-action-effect-audio {
  .ribbon-text.audio-ribbon {
    border-radius: 4px;

    &.selected {
      background-color: rgb(0 0 0 / 50%);

      .timeline-editor-action-left-stretch,
      .timeline-editor-action-right-stretch {
        background-color: rgb(0 0 0 / 10%);
      }
    }
  }
}

.music-ribbon {
  background-color: var(--color-music) !important;
}

.ribbon-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%; // Ensure the container uses the full width for proper alignment
  title-delay: 0s;
}

.timeline-editor-action {

  .ribbon-tooltip {
    position: absolute;
    bottom: 15px;
    display: none;
    z-index: 5;

    p {
      background: var(--color-secondary);
      padding: 5px;
      border-radius: 4px;
      box-shadow: 0 5px 10px rgba(0,0,0,0.4);

      * {
        display: inline;
      }

      .material-symbols-outlined {
        font-size: 16px;
        position: relative;
        top: 4px;
      }
    }

    .arrow-down {
      border-top-color: var(--color-secondary) !important;
      position: relative;
      left: 18px;
    }
  }

  &:hover .ribbon-tooltip {
    display: block;
  }

  &:active .ribbon-tooltip {
    // hide text block when dragging
    display: none !important;
  }

  // dragging action down

  &:has(.ribbon-text[data-delta-tracks="1"]) {
    position: relative;
    top: 150%; // normal is 50% + 100% track height
  }

  &:has(.ribbon-text[data-delta-tracks="2"]) {
    position: relative;
    top: 250%;
  }

  &:has(.ribbon-text[data-delta-tracks="3"]) {
    position: relative;
    top: 350%;
  }

  &:has(.ribbon-text[data-delta-tracks="4"]) {
    position: relative;
    top: 450%;
  }

  &:has(.ribbon-text[data-delta-tracks="5"]) {
    position: relative;
    top: 550%;
  }

  &:has(.ribbon-text[data-delta-tracks="6"]) {
    position: relative;
    top: 650%;
  }

  // dragging action up

  &:has(.ribbon-text[data-delta-tracks="-1"]) {
    position: relative;
    top: -50%;
  }

  &:has(.ribbon-text[data-delta-tracks="-2"]) {
    position: relative;
    top: -150%;
  }

  &:has(.ribbon-text[data-delta-tracks="-3"]) {
    position: relative;
    top: -250%;
  }

  &:has(.ribbon-text[data-delta-tracks="-4"]) {
    position: relative;
    top: -350%;
  }

  &:has(.ribbon-text[data-delta-tracks="-5"]) {
    position: relative;
    top: -450%;
  }

  &:has(.ribbon-text[data-delta-tracks="-6"]) {
    position: relative;
    top: -550%;
  }
}


.waveform {
  z-index: -1;
  display: inline-block;
  overflow-y: hidden;
  overflow-x: hidden;
  position: relative;
  height: 20px;
  margin-top: 3px;
}

.no-audio-text {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.timeline-editor {
  &-cursor {
    cursor: ew-resize;
    position: absolute;
    top: 32px;
    height: calc(100% - 32px);
    box-sizing: border-box;
    border-left: 1px solid var(--color-cursor) !important;
    border-right: 1px solid var(--color-cursor) !important;
    transform: translateX(-25%) scaleX(0.5);

    &-top {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, 0) scaleX(2);
      margin: auto;
      fill: var(--color-cursor);
    }

    &-area {
      width: 16px;
      height: 100%;
      cursor: ew-resize;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.timeline-editor-cursor-top path {
  fill: var(--color-cursor) !important; /* Default color is a fallback */
  pointer-events: auto;
}

.timeline-editor-cursor-area {
  pointer-events: none;
}

.timeline-editor-cursor-area::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 10px; /* Adjust the height as needed */
  pointer-events: auto; /* Enable pointer events on the top portion */
}

.timeline-editor-cursor-top {
  pointer-events: none; /* Enable pointer events on the cursor top */
}

.timeline-editor-time-area {
  color: var(--color-secondary) !important;
}

.timeline-editor,
.timeline-editor * {
  // color: var(--color-secondary)  !important;
  font-size: 12px;
  font-family: 'Raleway';
}

.timeline-editor {
  &-time-area {
    position: relative;
    height: 32px;
    flex: 0 0 auto;

    .ReactVirtualized__Grid {
      outline: none;

      &::-webkit-scrollbar {
        display: none;
        color: var(--color-secondary);
      }
    }

    &-interact {
      position: absolute;
      cursor: pointer;
      left: 0;
      top: 0;
    }
  }

  &-time-unit {
    border-right: 1px solid var(--color-secondary) !important;
    position: relative;
    box-sizing: content-box;
    height: 4px !important;
    bottom: 0 !important;
    top: auto !important;

    &-big {
      height: 8px !important;
    }
  }

  &-time-unit-scale {
    color: fade(var(--color-secondary), 60) !important;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%, -100%);
  }
}

.timeline-editor-edit-row {
  background-repeat: no-repeat, repeat;
  // gets overwritten by inline style when building, so "!important" is necessary
  background-image: linear-gradient(var(--color-light-grey), var(--color-light-grey)),
  linear-gradient(90deg, rgba(0, 0, 0, 0.1) 1px, transparent 0) !important;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}

.timeline-editor-edit-area {
  background: var(--timeline);
  // todo: these need to be re-enabled, but placed in the correct place
  // the timeline library does some very wierd stuff with the +20px that it adds at the start,
  // which is why this is fucked
  //border-top: 1px solid var(--color-secondary);
  //border-left: 1px solid var(--color-secondary);
}

.video-ribbon {
  background-color: var(--color-sidebar);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 4px;
}

.video-icon {
  max-width: 80%;
  max-height: 80%;
  object-fit: contain;
}

.timeline-editor-action:has(> .audio-ribbon),
.timeline-editor-action:has(> .prompt-ribbon) {
  .timeline-editor-action-left-stretch,
  .timeline-editor-action-right-stretch {
    width: 12px !important;
  }

  .timeline-editor-action-right-stretch::after {
    border-right-width: 12px;
  }

  .timeline-editor-action-left-stretch::after {
    border-left-width: 12px;
  }
}

.timeline-editor-action-right-stretch:hover::after {
  border-right-color: var(--color-accent-bright);
}

.timeline-editor-action-left-stretch:hover::after {
  border-left-color: var(--color-accent-bright);
}

.voice-ribbon {
  background-color: var(--color-voice);
  display: flex;
  flex-direction: column;
  //align-items: center;
  //justify-content: center;
  height: 100%;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  &.selected {
    background-color: var(--color-voice-accent);
  }

  .waveform {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .voice-content {
    display: flex;
    //align-items: center;
    //justify-content: center;
    gap: 8px;
    color: #fff;
    position: relative;
    z-index: 2;

    .voice-icon {
      font-size: 14px;
    }

    .voice-text {
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .timeline-editor-action:has(> .voice-ribbon) {
    .timeline-editor-action-left-stretch,
    .timeline-editor-action-right-stretch {
      display: none; // Hide the stretch elements
    }
  }
}
