body {
  font-family: 'Raleway', sans-serif;
}

h2 {
  font-family: 'Raleway', sans-serif;
  text-align: center;
  font-weight: 500;
  font-size: larger;
}

h3 {
  font-family: 'Raleway', sans-serif;
  text-align: left;
  font-weight: bold;
  font-size: 18px;
  color: var(--color-cursor);
  margin-bottom: 10px;
}

.text-style {
  font-family: 'Raleway', sans-serif;
  color: var(--color-cursor);
  font-size: 14px;
}

.voiceBox {
  background-color: var(--color-four);
  border: 1px solid var(--color-border);
  border-radius: 8px;
  padding: 20px;
  margin: 20px;
  display: flex;
  flex-direction: column;

  .voiceTextArea {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;

    textarea {
      @extend .text-style;
      flex: 1;
      border: 1px solid var(--color-border);
      border-radius: 4px;
      padding: 8px;
      resize: none;
      width: 100%;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .voiceOptions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    select {
      @extend .text-style;
      border: 1px solid var(--color-border);
      border-radius: 4px;
      padding: 8px;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }

    .characterCount {
      @extend .text-style;
      font-size: 12px;
    }
  }

  button {
    @extend .text-style;
    background-color: var(--color-sidebar);
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;

    &:hover {
      background-color: var(--color-accent);
    }

    &:disabled {
      background-color: var(--color-light-grey);
      cursor: not-allowed;
    }

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  audio {
    margin-top: 10px;
    width: 100%;
  }

  .voiceLoading {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
    font-size: 14px;
    color: var(--color-cursor);
  }

  .voicePlayer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;

    audio {
      flex: 1;
      margin-right: 10px;
    }

    .addToTimelineButton {
      @extend .text-style;
      background-color: var(--color-sidebar);
      color: #fff;
      border: none;
      border-radius: 20px;
      padding: 8px 16px;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: var(--color-accent);
      }
    }
  }
}
